<template>
  <section>
    <b-overlay
      variant="transparent"
      :show="overlay"
      :opacity="1.00"
      :blur="'2px'"
      rounded="lg"
    >
      <b-row class="m-3">
        <b-col cols="5">
          <b-form-input
            v-model="searchInput"
            :placeholder="emptySearch"
            autofocus
            autocomplete="off"
          />
        </b-col>
        <b-col cols="1" />
        <b-col cols="4">
          <b-pagination
            v-if="rows > perPage"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            size="lg"
            @input="allTemplates"
          />
        </b-col>
        <b-col
          cols="2"
          class="text-right"
        >
          <b-button
            variant="success"
            :disabled="disabled"
            class="pb-1 pt-1"
            @click="confirmSelection"
          >
            {{ $t("dataGeneric.save") }}
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          v-if="data.length == 0 && searchInput.length != 0"
          class="mb-2 ml-5 text-left"
        >
          <h5>{{ $t('subscriptions.notFound') }}</h5>
        </b-col>
        <b-col
          v-else-if="data.length > 0 && searchInput.length != 0"
          class="mb-2 ml-5 text-left"
        >
          <h5>{{ $t("common.selTempList") }}</h5>
        </b-col>
      </b-row>

      <b-row style="height:621px">
        <b-col
          style="height:603px"
          class="scroll-list list-group list-group-flush mb-1"
        >
          <b-overlay
            variant="transparent"
            :show="showList"
            :opacity="1.00"
            :blur="'2px'"
            rounded="lg"
            tag="ul"
          >
            <b-list-group

              horizontal
              class="ml-2"
            >
              <b-list-group-item
                tag="li"
                class="noBorder"
              >
                <h4 class="m-1">
                  {{ $t('category.card') }}
                </h4>
              </b-list-group-item>

              <b-list-group-item
                tag="li"
                class="noBorder"
              >
                <h4 class="m-1">
                  {{ $t('administrators.name') }}
                </h4>
              </b-list-group-item>
              <b-list-group-item
                tag="li"
                class="noBorder"
              >
                <h4 class="m-1">
                  key
                </h4>
              </b-list-group-item>
              <b-list-group-item
                tag="li"
                class="noBorder"
              >
                <h4 class="m-1">
                  {{ $t('code.type') }}
                </h4>
              </b-list-group-item>
            </b-list-group>
            <b-list-group-item
              v-for="res in data"
              v-show="data.length != 0"
              :key="res.node.id"
              :active="id == res.node.id"
              class="ml-2"
              tag="li"
              @click="
                clickRow(res)"
              @dblclick="confirmSelection"
            >
              <b-row class="align-items-center">
                <b-col>
                  <b-img
                    id="avatar"
                    style="object-fit: contain;"
                    rounded="lg"
                    :src="buildImageUrlBucket(res)"
                  />
                </b-col>

                <b-col>
                  <b-card-text class="mb-0 font-weight-bold">
                    <strong>{{ res.node.name }}</strong>
                  </b-card-text>
                </b-col>
                <b-col>
                  <b-card-text class="mb-0 font-weight-bold">
                    {{ res.node.key }}
                  </b-card-text>
                </b-col>
                <b-col>
                  <b-card-text class="mb-0 mfont-weight-bold">
                    {{ menuLocationOptions[res.node.menuLocationTv] }}
                  </b-card-text>
                </b-col>
              </b-row>
            </b-list-group-item>
          </b-overlay>
        </b-col>
      </b-row>
      <b-row />
    </b-overlay>
  </section>
</template>

<script>
import {
  BButton,
  BListGroup,
  BCardText,
  BImg,
  BOverlay,
  BPagination,
  BCol,
  BRow,
  BFormInput,
  BListGroupItem,

} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { messageError, isEmpty } from '@/store/functions'
import axios from '@axios'
import { getUserData } from '@/auth/utils'

const noCover = require('@/assets/images/backend/nocover.jpeg')

export default {
  components: {
    BCol,
    BRow,
    BImg,
    BCardText,
    BOverlay,
    BFormInput,
    BPagination,
    BButton,
    BListGroup,
    BListGroupItem,

  },
  directives: {
    Ripple,
  },

  data() {
    return {
      searchInput: '',
      rows: null,
      menuLocationOptions: {
        TOP: this.$t('top'),
        LEF: this.$t('left'),
      },
      rowSelected: null,
      overlay: false,
      perPage: 15,
      currentPage: 1,
      emptySearch: this.$t('slider.searchThreeCaracTem'),
      id: null,
      data: [],
      headers: {},
      userData: getUserData(),
      name: '',
      image: '',
      showList: false,
      disabled: true,
      templateSelected: [],

    }
  },
  watch: {
    searchInput(oldValue, newValue) {
      if (newValue !== oldValue) {
        if (this.searchInput.length >= 3) {
          this.currentPage = 1
          this.allTemplates()
        }
        if (this.searchInput.length < 3) {
          this.data = []
        }
        if (this.searchInput.length === 0) {
          this.allTemplates()
        }
      }
    },
  },
  mounted() {
    let defaultLang = 'es'
    try {
      defaultLang = this.userData.profile.client.defaultLanguage
      // eslint-disable-next-line no-empty
    } catch (error) { }

    this.headers = {
      'Accept-Language': defaultLang,
      'Content-Language': defaultLang,
    }
    this.allTemplates()
  },

  methods: {
    isEmpty,
    allTemplates() {
      const { headers } = this
      this.overlay = true
      axios
        .post('', {
          query: `
            query{  
              allTemplates(
              first:${this.perPage},
              offset:${this.perPage * (this.currentPage - 1)},
              name:"${this.searchInput}",
              ){
              pageInfo{
                hasPreviousPage
                hasNextPage
              }
              totalCount
              edgeCount
              edges{
                node{
                  id
                  name                  
                  sampleImage
                  key
                  menuLocationTv
                  isActive
                  
                }
              }
            }
          }
        `,
        }, { headers })
        .then(res => {
          messageError(res, this)
          this.data = res.data.data.allTemplates.edges
          this.rows = res.data.data.allTemplates.totalCount
          if (this.data.length === 0) { this.emptySearch = this.$t('slider.searchThreeCaracCat') }

          this.overlay = false
        }).catch(error => {
          console.log(error)
        })
    },
    clickRow(valueSelected) {
      this.templateSelected = valueSelected.node

      this.id = valueSelected.node.id
      this.name = valueSelected.node.name
      this.image = valueSelected.node.sampleImage.length !== 0 ? valueSelected.node.sampleImage
        : noCover
      this.disabled = false
    },

    buildImageUrlBucket(data) {
      if (data.node.sampleImage) {
        return data.node.sampleImage
      }
      return noCover
    },

    confirmSelection() {
      const data = []
      data.node = this.templateSelected

      this.$emit('confirm-selection', data)
    },
  },
}
</script>

<style scoped>
.scroll-list {
  overflow: hidden;
  overflow-y: scroll;
  height: 333px;
  margin-right: 20px;
}

.noBorder {
  border-bottom: 0px;
  width: -webkit-fill-available;
  width: -moz-available;
}
</style>
