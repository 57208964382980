<template>
  <section id="branding">
    <h1>{{ $t("branding.title") }}</h1>
    <h5 class="text-primary">
      {{ $t("branding.subtitle") }}
    </h5>
    <b-row
      class="match-height mt-2"
      align-v="stretch"
    >
      <b-col
        md="4"
        align-self="stretch"
      >
        <b-overlay
          variant="transparent"
          :show="showLogos"
        >
          <b-form @submit.prevent="updateLogos">
            <b-card lazy>
              <b-card-header>
                <b-card-title>{{ $t("branding.label") }}</b-card-title>
                <b-button
                  class="save-button"
                  type="submit"
                  variant="success"
                >
                  {{ $t("dataGeneric.save") }}
                </b-button>
              </b-card-header>
              <b-card-body>
                <b-row>
                  <b-col>
                    <b-form-group>
                      <b-row>
                        <image-element
                          :image-origin="imageLogoPr"
                          :image-type="'imageLogoPr'"
                          :can-delete="true"
                          md-length="6"
                          :label="$t('branding.span')"
                          @deleteImage="deleteImage"
                          @saveImage="loadImage"
                        />
                        <image-element
                          :image-origin="imageLogoMo"
                          :image-type="'imageLogoMo'"
                          :can-delete="true"
                          md-length="6"
                          :label="$t('branding.span2')"
                          @deleteImage="deleteImage"
                          @saveImage="loadImage"
                        />
                      </b-row>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-form>
        </b-overlay>
      </b-col>
      <b-col
        md="4"
        align-self="stretch"
      >
        <b-overlay
          variant="transparent"
          :show="showInteractivitiesLogo"
        >
          <b-form @submit.prevent="updateInteractivitiesLogo">
            <b-card lazy>
              <b-card-header>
                <b-card-title>{{ $t("branding.interactivitiesLogo") }}</b-card-title>
                <b-button
                  class="save-button"
                  type="submit"
                  variant="success"
                >
                  {{ $t("dataGeneric.save") }}
                </b-button>
              </b-card-header>
              <b-card-body>
                <b-row>
                  <b-col>
                    <b-form-group>
                      <b-row>
                        <image-element
                          class="mx-auto"
                          :image-origin="imageLogoInteractivities"
                          :image-type="'imageLogoInteractivities'"
                          :can-delete="true"
                          md-length="6"
                          :label="$t('branding.logotipo')+' (150x150 px)'"
                          @deleteImage="deleteImage"
                          @saveImage="loadImage"
                        />
                      </b-row>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-form>
        </b-overlay>
      </b-col>
      <b-col
        md="4"
        align-self="stretch"
      >
        <b-overlay
          variant="transparent"
          :show="showColor"
        >
          <b-form @submit.prevent="updateColor">
            <b-card lazy>
              <b-card-header>
                <b-card-title>{{ $t('branding.label2') }}</b-card-title>
                <b-button
                  class="save-button"
                  type="submit"
                  variant="success"
                >
                  {{ $t("dataGeneric.save") }}
                </b-button>
              </b-card-header>
              <b-card-body>
                <b-row>
                  <b-col>
                    <b-form-group>
                      <b-row>
                        <b-col md="6">
                          <label class="d-flex justify-content-between mb-1">
                            {{ $t("branding.span3") }}
                          </label>
                          <b-form-group
                            class="
                                                                      mb-2
                                                                      mr-1
                                                                      embed-responsive embed-responsive-16by9
                                                                    "
                          >
                            <div
                              class="base-color-input embed-responsive-item"
                              :style="{
                                'background-color': `${inputColor}`
                              }"
                              @click="chooseInput('inputColor')"
                            >
                              <span>{{ inputColor }}</span>
                              <input
                                id="inputColor"
                                v-model="inputColor"
                                type="color"
                              >
                            </div>
                          </b-form-group>
                          <!--  <div class="d-flex mt-1 mb-2">
                            <span>{{ $t("branding.span3") }}</span>
                          </div> -->
                        </b-col>
                        <b-col md="6">
                          <label class="d-flex justify-content-between mb-1">
                            {{ $t("branding.labelColor") }}
                          </label>
                          <b-form-group
                            class="
                                                                      mb-2
                                                                      mr-1
                                                                      embed-responsive embed-responsive-16by9
                                                                    "
                          >
                            <div
                              class="base-color-input embed-responsive-item"
                              :style="{
                                'background-color': `${backgroundColor}`
                              }"
                              @click="chooseInput('backgroundColor')"
                            >
                              <span>{{ backgroundColor }}</span>
                              <input
                                id="backgroundColor"
                                v-model="backgroundColor"
                                type="color"
                              >
                            </div>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-form>
        </b-overlay>
      </b-col>
    </b-row>

    <!-- Imágenes de fondos -->
    <b-row class="match-height mt-2">
      <b-col>
        <b-overlay
          variant="transparent"
          :show="showFondos"
        >
          <b-form @submit.prevent="updateFondos">
            <b-card lazy>
              <b-card-header>
                <b-card-title>{{ $t("branding.label3") }}</b-card-title>
                <b-button
                  class="save-button"
                  type="submit"
                  variant="success"
                >
                  {{ $t("dataGeneric.save") }}
                </b-button>
              </b-card-header>
              <b-card-body>
                <b-row>
                  <b-col class="ml-2">
                    <b-form-group class="mb-2 mr-1">
                      <b-row class="my-2 justify-content-around">
                        <image-element
                          :image-origin="imageFondoPr"
                          :image-type="'imageFondoPr'"
                          :can-delete="true"
                          cols-length="3"
                          :label="$t('branding.span4')"
                          @deleteImage="deleteImage"
                          @saveImage="loadImage"
                        />
                        <image-element
                          :image-origin="imageFondoAd"
                          :image-type="'imageFondoAd'"
                          :can-delete="true"
                          cols-length="3"
                          :label="$t('branding.span5')"
                          @deleteImage="deleteImage"
                          @saveImage="loadImage"
                        />
                      </b-row>
                      <b-row
                        class="mt-3 mb-1"
                        style="margin-left: 0.3rem"
                      >
                        <b-col>
                          <b-form-checkbox
                            id="efectoBlur"
                            v-model="checkboxBlur"
                            :switch="true"
                          >
                            {{ $t("branding.span7") }}
                          </b-form-checkbox>
                        </b-col>
                      </b-row>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-form>
        </b-overlay>
      </b-col>
    </b-row>
    <!-- Logo del reproductor -->
    <b-row class="match-height mt-2">
      <b-col>
        <b-overlay
          variant="transparent"
          :show="showMosca"
        >
          <b-form @submit.prevent="updateMosca">
            <b-card lazy>
              <b-card-header>
                <b-card-title>{{ $t('branding.logo') }}</b-card-title>
                <b-button
                  class="save-button"
                  type="submit"
                  variant="success"
                >
                  {{ $t("dataGeneric.save") }}
                </b-button>
              </b-card-header>
              <b-card-body>
                <b-row>
                  <b-col class="ml-2">
                    <b-form-group class="mb-2 mr-1">
                      <b-row>
                        <b-col md="4">
                          <b-row>
                            <b-col>
                              <label>{{ $t('branding.pos') }}</label>
                              <b-form-select
                                v-model="flyPosition"
                                :options="moscaOptions"
                              />
                            </b-col>
                          </b-row>
                          <b-row class="mt-2 justify-content-center">
                            <image-element
                              :image-origin="flyImage"
                              :image-type="'flyImage'"
                              :can-delete="true"
                              :label-info="$t('infoImage.fly')"
                              md-length="8"
                              :label="$t('branding.prevLogo')"
                              @deleteImage="deleteImage"
                              @saveImage="loadImage"
                            />
                          </b-row>
                        </b-col>
                        <b-col md="8">
                          <b-row>
                            <b-col>
                              <h3 class="mb-1 text-center">
                                {{ $t('branding.prevLogo') }}
                              </h3>
                            </b-col>
                          </b-row>
                          <b-row class="justify-content-center">
                            <b-col sm="8">
                              <div
                                v-if="flyPosition != 'NOT'"
                                class="
                                    base-image-input
                                    mw-100
                                    embed-responsive embed-responsive-16by9
                                  "
                                :style="{
                                  background: `url(${flyImageUrl == null
                                    ? flyImage
                                    : flyImageUrl
                                  }) ${flyPosition == 'DOR'
                                    ? `90% 90%`
                                    : flyPosition == 'UPL'
                                      ? `10% 10%`
                                      : flyPosition == 'UPR'
                                        ? `90% 10%`
                                        : flyPosition == 'DOL'
                                          ? `10% 90%`
                                          : ` `
                                  }/30px no-repeat,url(${require('@/assets/images/backend/tv.png')}) center/cover no-repeat`
                                }"
                              />
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-form>
        </b-overlay>
      </b-col>
    </b-row>
    <!-- TEMPLATES -->
    <b-row v-if="isSuperuser">
      <b-col>
        <b-overlay
          variant="transparent"
          :show="showTemplates"
        >
          <b-form @submit.prevent="updateTemplates">
            <b-card lazy>
              <b-card-header>
                <b-card-title>
                  {{ $t("templatePlatf") }}
                  <feather-icon
                    v-if="templatesId"
                    icon="XCircleIcon"
                    size="18"
                    class="text-danger cursor-pointer ml-2"
                    @click="clearTemplate()"
                  />
                </b-card-title>
                <b-button
                  class="save-button"
                  type="submit"
                  variant="success"
                >
                  {{ $t("dataGeneric.save") }}
                </b-button>
              </b-card-header>
              <b-card-body>
                <b-row>
                  <b-col class="ml-2">
                    <b-form-group label-for="templates">
                      <div
                        class="d-flex justify-content-center align-items-center"
                        @click="$refs['template-modal'].show()"
                      >
                        <b-img
                          id="avatar"
                          class="mr-2"
                          rounded="lg"
                          :src="buildImageUrl(templatesImage)"
                          @error="errorImg"
                        />

                        <b-form-input
                          id="content"
                          v-model="templatesName"
                          autocomplete="new-password"
                          :placeholder="$t('addTem')"
                          disabled
                        />

                        <b-button
                          class="ml-2"
                          variant="info"
                          @click="$refs['template-modal'].show()"
                        >
                          {{ $t('Select') }}
                        </b-button>
                      </div>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-form>
        </b-overlay>
      </b-col>
    </b-row>
    <!-- Tarjetas de contenidos -->
    <b-row class="match-height mt-2">
      <b-col>
        <b-overlay
          variant="transparent"
          :show="showCard"
        >
          <b-form @submit.prevent="updateCardContents">
            <b-card lazy>
              <b-card-header>
                <b-card-title>{{ $t("branding.label5") }}</b-card-title>
                <b-button
                  class="save-button"
                  type="submit"
                  variant="success"
                >
                  {{ $t("dataGeneric.save") }}
                </b-button>
              </b-card-header>
              <b-card-body>
                <b-row>
                  <b-col class="ml-2">
                    <b-form-group>
                      <b-row
                        class="my-2"
                        style="justify-content: space-around;"
                      >
                        <b-col cols="3">
                          <div class="mb-1">
                            <span>{{ $t("branding.span11") }}</span>
                          </div>
                          <b-form-group
                            class="
                                                                      mb-2
                                                                      mr-1
                                                                      embed-responsive embed-responsive-16by9
                                                                    "
                          >
                            <div
                              id="imageCatTV"
                              class="base-card-input embed-responsive-item"
                              @click="showModalCard('imageCatTV')"
                            >
                              <span
                                class="placeholder"
                                :style="imageCatTV != null
                                  ? `background: url('${imageCatTV.sampleImage}')center/contain no-repeat;`
                                  : ''"
                              >
                                <feather-icon
                                  v-if="imageCatTV == null"
                                  icon="LayoutIcon"
                                  size="80"
                                />
                              </span>
                            </div>
                          </b-form-group>

                          <div class="d-flex justify-content-center mt-1">
                            {{
                              imageCatTV != null
                                ? imageCatTV.name
                                : $t('noSelect')
                            }}
                          </div>
                        </b-col>
                        <b-col cols="3">
                          <div class="mb-1">
                            <span>{{ $t("branding.span13") }}</span>
                          </div>
                          <b-form-group
                            class="
                                                                      mb-2
                                                                      mr-1
                                                                      embed-responsive embed-responsive-16by9
                                                                    "
                          >
                            <div
                              id="imageCatMo"
                              class="base-card-input embed-responsive-item"
                              @click="showModalCard('imageCatMo')"
                            >
                              <span
                                class="placeholder"
                                :style="imageCatMo != null
                                  ? `background: url('${imageCatMo.sampleImage}')center/contain no-repeat;`
                                  : ''"
                              >
                                <feather-icon
                                  v-if="imageCatMo == null"
                                  icon="LayoutIcon"
                                  size="80"
                                />

                              </span>
                            </div>
                          </b-form-group>

                          <div class="d-flex justify-content-center mt-1">
                            {{
                              imageCatMo != null
                                ? imageCatMo.name
                                : $t('noSelect')
                            }}
                          </div>
                        </b-col>
                        <b-col cols="3">
                          <div class="mb-1">
                            <span>{{ $t('branding.cardAlt') }}</span>
                          </div>
                          <b-form-group
                            class="
                                                                      mb-2
                                                                      mr-1
                                                                      embed-responsive embed-responsive-16by9
                                                                    "
                          >
                            <div
                              id="imageCatAlt"
                              class="base-card-input embed-responsive-item"
                              @click="showModalCard('imageCatAlt')"
                            >
                              <span
                                class="placeholder"
                                :style="imageCatAlt != null
                                  ? `background: url('${imageCatAlt.sampleImage}')center/contain no-repeat;`
                                  : ''"
                              >
                                <feather-icon
                                  v-if="imageCatAlt == null"
                                  icon="LayoutIcon"
                                  size="80"
                                />
                                <!-- Tarjeta contenido TV -->
                              </span>
                            </div>
                          </b-form-group>

                          <div class="d-flex justify-content-center mt-1">
                            {{
                              imageCatAlt != null
                                ? imageCatAlt.name
                                : $t('noSelect')
                            }}
                          </div>
                        </b-col>
                      </b-row>
                      <b-row style="justify-content: space-around;">
                        <b-col cols="3">
                          <div class="mb-1">
                            <span>{{ $t("branding.span12") }}</span>
                          </div>
                          <b-form-group
                            class="
                                                                  mb-2
                                                                  mr-1
                                                                  embed-responsive embed-responsive-16by9
                                                                "
                          >
                            <div
                              id="imageContTV"
                              class="base-card-input embed-responsive-item"
                              @click="showModalCard('imageContTV')"
                            >
                              <span
                                class="placeholder"
                                :style="imageContTV != null
                                  ? `background: url('${imageContTV.sampleImage}')center/contain no-repeat;`
                                  : ''"
                              >
                                <feather-icon
                                  v-if="imageContTV == null"
                                  icon="LayoutIcon"
                                  size="80"
                                />
                                <!-- Tarjeta contenido TV -->
                              </span>
                            </div>
                          </b-form-group>
                          <div class="d-flex justify-content-center mt-1">
                            {{
                              imageContTV != null
                                ? imageContTV.name
                                : $t('noSelect')
                            }}
                          </div>
                        </b-col>
                        <b-col cols="3">
                          <div class="mb-1">
                            <span>{{ $t("branding.span14") }}</span>
                          </div>
                          <b-form-group
                            class="
                                                                  mb-2
                                                                  mr-1
                                                                  embed-responsive embed-responsive-16by9
                                                                "
                          >
                            <div
                              id="imageContMo"
                              class="base-card-input embed-responsive-item"
                              @click="showModalCard('imageContMo')"
                            >
                              <span
                                class="placeholder"
                                :style="imageContMo != null
                                  ? `background: url('${imageContMo.sampleImage}')center/contain no-repeat;`
                                  : ''"
                              >
                                <feather-icon
                                  v-if="imageContMo == null"
                                  icon="LayoutIcon"
                                  size="80"
                                />
                              </span>
                            </div>
                          </b-form-group>

                          <div class="d-flex justify-content-center mt-1">
                            {{
                              imageContMo != null
                                ? imageContMo.name
                                : $t('noSelect')
                            }}
                          </div>
                        </b-col>
                        <b-col cols="3">
                          <div class="mb-1">
                            <span>{{ $t('branding.cardAltCont') }}</span>
                          </div>
                          <b-form-group
                            class="
                                                                      mb-2
                                                                      mr-1
                                                                      embed-responsive embed-responsive-16by9
                                                                    "
                          >
                            <div
                              id="imageContAlt"
                              class="base-card-input embed-responsive-item"
                              @click="showModalCard('imageContAlt')"
                            >
                              <span
                                class="placeholder"
                                :style="imageContAlt != null
                                  ? `background: url('${imageContAlt.sampleImage}')center/contain no-repeat;`
                                  : ''"
                              >
                                <feather-icon
                                  v-if="imageContAlt == null"
                                  icon="LayoutIcon"
                                  size="80"
                                />
                              </span>
                            </div>
                          </b-form-group>

                          <div class="d-flex justify-content-center mt-1">
                            {{
                              imageContAlt != null
                                ? imageContAlt.name
                                : $t('noSelect')
                            }}
                          </div>
                        </b-col>
                      </b-row>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-form>
        </b-overlay>
      </b-col>
    </b-row>
    <!-- Tarjetas de productos -->
    <b-row class="match-height mt-2">
      <b-col>
        <b-overlay
          variant="transparent"
          :show="showProducts"
        >
          <b-form @submit.prevent="updateCardProducts">
            <b-card lazy>
              <b-card-header>
                <b-card-title>{{ $t("branding.label6") }}</b-card-title>
                <b-button
                  class="save-button"
                  type="submit"
                  variant="success"
                >
                  {{ $t("dataGeneric.save") }}
                </b-button>
              </b-card-header>
              <b-card-body>
                <b-row>
                  <b-col class="ml-2">
                    <b-form-group>
                      <b-row class="my-2 justify-content-around">
                        <b-col cols="3">
                          <div class="mb-1">
                            <span>{{ $t('contents.categoryCard') }}</span>
                          </div>
                          <b-form-group
                            class="
                                                                      mb-2
                                                                      mr-1
                                                                      embed-responsive embed-responsive-16by9
                                                                    "
                          >
                            <div
                              id="cardProducts"
                              class="base-card-input embed-responsive-item"
                              @click="showModalCard('cardProducts')"
                            >
                              <span
                                class="placeholder"
                                :style="cardProducts != null
                                  ? `background: url('${cardProducts.sampleImage}')center/contain no-repeat;`
                                  : ''"
                              >
                                <feather-icon
                                  v-if="cardProducts == null"
                                  icon="LayoutIcon"
                                  size="80"
                                />
                              </span>
                            </div>
                          </b-form-group>

                          <div class="d-flex justify-content-center mt-1">
                            {{
                              cardProducts != null
                                ? cardProducts.name
                                : $t('noSelect')
                            }}
                          </div>
                        </b-col>
                        <b-col cols="3">
                          <div class="mb-1">
                            <span>{{ $t('contents.categoryCardPrd') }}</span>
                          </div>
                          <b-form-group
                            class="
                                                                      mb-2
                                                                      mr-1
                                                                      embed-responsive embed-responsive-16by9
                                                                    "
                          >
                            <div
                              id="cardCategoriesProducts"
                              class="base-card-input embed-responsive-item"
                              @click="showModalCard('cardCategoriesProducts')"
                            >
                              <span
                                class="placeholder"
                                :style="cardCategoriesProducts != null
                                  ? `background: url('${cardCategoriesProducts.sampleImage}')center/contain no-repeat;`
                                  : ''"
                              >
                                <feather-icon
                                  v-if="cardCategoriesProducts == null"
                                  icon="LayoutIcon"
                                  size="80"
                                />

                                <!--   Tarjeta categoría Producto  -->
                              </span>
                            </div>
                          </b-form-group>

                          <div class="d-flex justify-content-center mt-1">
                            {{
                              cardCategoriesProducts != null
                                ? cardCategoriesProducts.name
                                : $t('noSelect')
                            }}
                          </div>
                        </b-col>
                      </b-row>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-form>
        </b-overlay>
      </b-col>
    </b-row>
    <b-row class="match-height mt-2">
      <b-col>
        <b-overlay
          variant="transparent"
          :show="showLottie"
        >
          <b-form @submit.prevent="updateLottie">
            <b-card lazy>
              <b-card-header>
                <b-card-title>
                  {{ $t('lottie') }}
                </b-card-title>
                <b-button
                  class="save-button"
                  type="submit"
                  variant="success"
                >
                  {{ $t("dataGeneric.save") }}
                </b-button>
              </b-card-header>
              <b-card-body>
                <b-row style="align-items: center;">
                  <b-col md="11">
                    <b-form-group :label="$t('lotMov')">
                      <b-form-file
                        id="lottieMobile"
                        v-model="lottieMobile"
                        accept=".json"
                        class="mr-1"
                        :placeholder="archivoMobile"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <a
                      v-if="archivoMobile"
                      :href="archivoMobile"
                      download
                      target="_blank"
                    >
                      Archivo Json</a>
                  </b-col>
                </b-row>
                <b-row style="align-items: center;">
                  <b-col md="11">
                    <b-form-group :label="$t('lottieTV')">
                      <b-form-file
                        id="lottieTv"
                        v-model="lottieTv"
                        accept=".json"
                        class="mr-1"
                        :placeholder="archivoTv"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <a
                      v-if="archivoTv"
                      :href="archivoTv"
                      download
                      target="_blank"
                    >
                      {{ $t('jsoFile') }}</a>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-form>
        </b-overlay>
      </b-col>
    </b-row>

    <b-modal
      ref="modalCard"
      size="xl"
      hide-footer
      :title="$t('modal.card')"
    >
      <div class="d-block text-center">
        <card-selector-modal
          :seleccionado="idSelectCard"
          @id="SelectCardId"
        />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="hideModal"
      >
        {{ $t("dataGeneric.clear") }}
      </b-button>
    </b-modal>
    <b-modal
      ref="template-modal"
      size="xl"
      hide-footer
      :title="$t('templates')"
    >
      <div class="d-block text-center">
        <template-selector @confirm-selection="templateSelection" />
        <!-- @info="SelectContentInfo"
        @image="SelectContentImage" -->
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="$refs['template-modal'].hide()"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
  </section>
</template>

<script>
import {
  messageError, showToast,
} from '@/store/functions'
import '@core/assets/fonts/icons/css/all.css'
import '@core/assets/fonts/icons/scss/fontawesome.scss'
import {
  BForm,
  BRow,
  BCol,
  BFormCheckbox,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BImg,
  BFormInput,
  BFormSelect,
  BButton,
  BFormGroup,
  BCard,
  BFormFile,
  BOverlay,
} from 'bootstrap-vue'
import axios from '@axios'
import Ripple from 'vue-ripple-directive'
import { getUserData } from '@/auth/utils'
import ImageElement from '@/views/components/ImageElement.vue'
import CardSelectorModal from '../../common/CardSelectorModal.vue'
import TemplateSelector from '../../common/TemplateSelector.vue'

const fileError = require('@/assets/images/backend/file-error.png')

const noCover = require('@/assets/images/backend/nocover.jpeg')

export default {
  components: {
    BOverlay,
    BCardHeader,
    BCardTitle,
    BCardBody,
    CardSelectorModal,
    BFormGroup,
    BImg,
    BForm,
    TemplateSelector,
    BRow,
    BCol,
    BFormInput,
    BFormCheckbox,
    BFormSelect,
    BButton,
    BCard,
    ImageElement,
    BFormFile,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      showTemplates: false,
      typeCard: null,
      userData: getUserData(),
      inputColor: '#000000',
      backgroundColor: '#000000',
      showLottie: false,
      selected: null,
      flyImageUrl: null,
      archivoMobile: null,
      archivoTv: null,
      idSelectCard: null,
      lottieMobile: null,
      lottieTv: null,
      imageLogoPr: null,
      imageLogoMo: null,
      imageFondoPr: null,
      imageFondoAd: null,
      imageFondoMo: null,
      imageCatTV: null,
      imageContTV: null,
      imageCatMo: null,
      imageContMo: null,
      cardCategoriesProducts: null,
      cardProducts: null,
      imageContAlt: null,
      imageCatAlt: null,
      imageDataDosMo: null,
      imageDataMo: null,
      checkboxBlur: false,
      showMosca: false,
      templatesId: null,
      templatesName: null,
      templatesImage: null,
      showLogos: false,
      showInteractivitiesLogo: false,
      showColor: false,
      showFondos: false,
      showCard: false,
      showProducts: false,
      flyImage: null,
      flyPosition: 'NOT',
      moscaOptions: [
        { value: 'NOT', text: this.$t('noFly') },
        { value: 'UPL', text: this.$t('upLeft') },
        { value: 'UPR', text: this.$t('upRight') },
        { value: 'DOL', text: this.$t('downLeft') },
        { value: 'DOR', text: this.$t('downRight') },
      ],
      imageLogoInteractivities: null,
      isSuperuser: false,

    }
  },

  mounted() {
    this.isSuperuser = this.userData.isSuperuser

    this.getData()
  },

  methods: {
    SelectCardId(field) {
      switch (this.typeCard) {
        case 'imageCatTV':
          this.imageCatTV = field

          break
        case 'imageContTV':
          this.imageContTV = field

          break
        case 'imageCatMo':
          this.imageCatMo = field
          break
        case 'imageContMo':
          this.imageContMo = field
          break
        case 'cardProducts':
          this.cardProducts = field
          break
        case 'imageContAlt':
          this.imageContAlt = field
          break
        case 'imageCatAlt':
          this.imageCatAlt = field
          break
        case 'cardCategoriesProducts':
          this.cardCategoriesProducts = field
          break
        case 'imageDataMo':
          this.imageDataMo = field
          break
        case 'imageDataDosMo':
          this.imageDataDosMo = field
          break
        default:
          break
      }
      this.hideModal()
    },
    deleteImage(type) {
      this.$swal({
        title: this.$t('removData'),
        text: this.$t('sure'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('code.confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.isConfirmed) {
            const config = {
              timeout: 1000 * 60 * 30,
            }
            const data = new FormData()
            const { id } = this.userData.profile.client

            const query = `
                mutation{
                  updateClient(id:"${id}",input:{
                  }){
                    client{
                          id
                        
                        }
                      }
                    }
              `
            data.append('query', query)

            switch (type) {
              case 'imageLogoPr':
                this.showLogos = true
                this.imageLogoPr = null
                data.append('deleteFile', 'logo')

                break
              case 'imageLogoMo':
                this.showLogos = true
                this.imageLogoMo = null
                data.append('deleteFile', 'logo_mono')

                break
              case 'imageFondoPr':
                this.showFondos = true

                this.imageFondoPr = null
                data.append('deleteFile', 'background')

                break
              case 'imageFondoAd':
                this.showFondos = true

                this.imageFondoAd = null
                data.append('deleteFile', 'background_admin')

                break
              case 'backgroundMobile':
                this.showFondos = true

                this.backgroundMobileSelected = null
                data.append('deleteFile', 'background_mobile_')

                break
              case 'flyImage':
                this.showMosca = true
                this.flyImage = null
                data.append('deleteFile', 'fly_image')

                break
              case 'imageLogoInteractivities':
                this.showInteractivitiesLogo = true
                this.imageLogoInteractivities = null
                data.append('deleteFile', 'logo_interactivities')

                break
              default:
                break
            }
            axios.post('', data, config).then(createResponse => {
              messageError(createResponse, this)
              this.showDesign = false
              this.showMosca = false
              this.showFondos = false
              this.showLogos = false
              this.showInteractivitiesLogo = false

              showToast(this.$t('success'), 1, this)
            }).catch(() => {
              this.showDesign = false

              showToast(this.$t('code.updateDataError'), 2, this)
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    getData() {
      const client = this.userData.profile.client.id
      axios
        .post('', {
          query: `
          query{  
            client(id:"${client}"){
            logo
            color1
            color3
            logoMono
            background
            backgroundAdmin
            menuImage
            isBackgroundBlur
            logoInteractivities
            flyPosition
            flyImage
            lottieMobile
            lottieTv
            templates {
              id
              name
              sampleImage
            }
            content{
              id
              name  
              contentResources(isInitial:true){
                edges{
                  node{
                    isInitial
                    resource{
                      file{
                        fileUrl
                      }
                    }
                  }
                }
              }
            }
            cardContentsMobile{
              id
              name    
              sampleImage        
              
            }
            cardCategoriesMobile {
              id
              name  
              sampleImage    
            
            }
            cardCategoriesProducts{
              id
              name
              sampleImage 
            }
          cardProducts{
              name
              id   
              sampleImage  
            
          }       
          cardCategories {
            id
            name
            sampleImage         
            }
            cardContentsAlternative {
              id
              name
              sampleImage 
            }
            cardCategoriesAlternative {
              
              id
              name
              sampleImage 
            }
            
            cardContents {
              id
              name
              sampleImage  
            }
          }  
        }
        `,
        })
        .then(res => {
          messageError(res, this)

          const response = res.data.data.client
          this.templatesId = response.templates
            ? response.templates.id
            : ''
          this.templatesName = response.templates
            ? response.templates.name
            : ''
          this.templatesImage = response.templates
            ? response.templates.sampleImage
            : ''
          this.imageCatTV = response.cardCategories
            ? response.cardCategories
            : null
          this.imageContTV = response.cardContents
            ? response.cardContents
            : null
          this.imageCatMo = response.cardCategoriesMobile
            ? response.cardCategoriesMobile
            : null
          this.imageContMo = response.cardContentsMobile
            ? response.cardContentsMobile
            : null
          this.cardProducts = response.cardProducts
            ? response.cardProducts
            : null
          this.imageContAlt = response.cardContentsAlternative
            ? response.cardContentsAlternative
            : null
          this.imageCatAlt = response.cardCategoriesAlternative
            ? response.cardCategoriesAlternative
            : null
          this.cardCategoriesProducts = response.cardCategoriesProducts
            ? response.cardCategoriesProducts
            : null
          this.archivoMobile = response.lottieMobile
            ? response.lottieMobile
            : null
          this.archivoTv = response.lottieTv ? response.lottieTv : null

          this.inputColor = response.color1
          this.backgroundColor = response.color3
          this.flyPosition = response.flyPosition

          this.imageLogoPr = response.logo !== '' ? response.logo : ''

          this.flyImage = response.flyImage !== '' ? response.flyImage : ''

          this.imageLogoMo = response.logoMono !== '' ? response.logoMono : ''

          this.imageFondoPr = response.background !== '' ? response.background : ''

          this.imageFondoAd = response.backgroundAdmin !== '' ? response.backgroundAdmin : ''

          this.imageFondoMo = response.menuImage !== '' ? response.menuImage : ''

          this.checkboxBlur = response.isBackgroundBlur

          this.imageLogoInteractivities = response.logoInteractivities !== '' ? response.logoInteractivities : ''
        })
    },
    chooseInput(dato) {
      document.getElementById(dato).click()
    },
    showModalCard(type) {
      this.$refs.modalCard.show()
      switch (type) {
        case 'imageCatTV':
          this.idSelectCard = this.imageCatTV

          break
        case 'imageContTV':
          this.idSelectCard = this.imageContTV

          break

        case 'imageCatMo':
          this.idSelectCard = this.imageCatMo

          break
        case 'imageContMo':
          this.idSelectCard = this.imageContMo

          break
        case 'cardProducts':
          this.idSelectCard = this.cardProducts

          break
        case 'cardCategoriesProducts':
          this.idSelectCard = this.cardCategoriesProducts

          break
        case 'imageContAlt':
          this.idSelectCard = this.imageContAlt

          break
        case 'imageCatAlt':
          this.idSelectCard = this.imageCatAlt

          break

        default:
          break
      }
      this.typeCard = type
    },
    hideModal() {
      this.$refs.modalCard.hide()
    },
    loadImage(item) {
      const { data, type } = item

      switch (type) {
        case 'imageLogoPr':
          this.imageLogoPr = data
          break
        case 'imageLogoMo':
          this.imageLogoMo = data

          break
        case 'imageFondoPr':
          this.imageFondoPr = data

          break
        case 'imageFondoAd':
          this.imageFondoAd = data

          break
        case 'flyImage':
          this.flyImage = data
          this.flyImageUrl = URL.createObjectURL(this.flyImage)
          break
        case 'imageLogoInteractivities':
          this.imageLogoInteractivities = data
          break
        default:
          break
      }
    },
    clearTemplate() {
      this.templatesName = null
      this.templatesId = null
      this.templatesImage = null
    },
    updateMosca(event) {
      this.showMosca = true
      event.preventDefault()
      const { id } = this.userData.profile.client
      const config = {
        timeout: 1000 * 60 * 30,
      }
      const data = new FormData()
      const query = `
      mutation{
        updateClient(id:"${id}",input:{
          flyPosition: ${this.flyPosition}                
        }){
          client{
            id
            name
            videotype
            video
            logo
            logoMono
           
          }
        }
      }
     `
      data.append('query', query)
      data.append('fly_image', this.flyImage)

      axios
        .post('', data, config)
        .then(res => {
          messageError(res, this)
          this.getData()

          showToast(this.$t('code.logo'), 1, this)
          this.showMosca = false
        })
        .catch(() => {
          showToast(this.$t('code.errorLogo'), 2, this)
          this.showMosca = false
        })
    },
    updateLogos(event) {
      this.showLogos = true
      event.preventDefault()
      const { id } = this.userData.profile.client
      const config = {
        timeout: 1000 * 60 * 30,
      }
      const data = new FormData()
      const query = `
      mutation{
        updateClient(id:"${id}",input:{
          videotype: CON                 
        }){
          client{
            id
            name
            videotype
            video
            logo
            logoMono
           
          }
        }
      }
     `
      data.append('query', query)
      data.append('logo', this.imageLogoPr)
      data.append('logo_mono', this.imageLogoMo)

      axios
        .post('', data, config)
        .then(res => {
          messageError(res, this)
          this.getData()

          showToast(this.$t('code.logo'), 1, this)
          this.showLogos = false
        })
        .catch(() => {
          showToast(this.$t('code.errorLogo'), 2, this)
          this.showLogos = false
        })
    },
    updateColor(event) {
      this.showColor = true
      event.preventDefault()
      const { id } = this.userData.profile.client
      axios
        .post('', {
          query: `
             mutation{
              updateClient(id: "${id}" ,input:{               
               color1: "${this.inputColor}",
               color3: "${this.backgroundColor}",               
              }){
                client{
                  id
                  name
                  color1
                }
              }
            }
        `,
        })
        .then(res => {
          messageError(res, this)

          showToast(this.$t('code.color'), 1, this)
          this.showColor = false
        })
        .catch(() => {
          showToast(this.$t('code.errorcolor'), 2, this)
          this.showColor = false
        })
    },
    errorImg(e) {
      e.target.src = fileError
    },
    updateFondos(event) {
      this.showFondos = true
      event.preventDefault()
      const { id } = this.userData.profile.client
      const config = {
        timeout: 1000 * 60 * 30,
      }
      const data = new FormData()
      const query = `
      mutation{
        updateClient(id:"${id}",input:{
         isBackgroundBlur: ${this.checkboxBlur}             
        }){
          client{
            id
            name
            logo
            logoMono
            background
            backgroundAdmin
            menuImage
          }
        }
      }
     `

      data.append('query', query)
      data.append('background', this.imageFondoPr)
      data.append('background_admin', this.imageFondoAd)
      data.append('menu_image', this.imageFondoMo)

      axios
        .post('', data, config)
        .then(res => {
          messageError(res, this)
          this.getData()

          showToast(this.$t('code.back'), 1, this)
          this.showFondos = false
        })
        .catch(() => {
          showToast(this.$t('code.errorback'), 2, this)
          this.showFondos = false
        })
    },
    updateCardContents(event) {
      this.showCard = true
      event.preventDefault()

      const { id } = this.userData.profile.client
      axios
        .post('', {
          query: `
             mutation{
              updateClient(id:"${id}",input:{
                ${this.imageContTV != null
    ? `cardContents:"${this.imageContTV.id}",`
    : ''
}        
                 ${this.imageCatTV != null
    ? `cardCategories:"${this.imageCatTV.id}",`
    : ''
} 
                 ${this.imageContMo != null
    ? `cardContentsMobile:"${this.imageContMo.id}",`
    : ''
} 
                 ${this.imageCatMo != null
    ? `cardCategoriesMobile:"${this.imageCatMo.id}",`
    : ''
} 
                 ${this.imageContAlt != null
    ? `cardContentsAlternative:"${this.imageContAlt.id}",`
    : ''
}  
                 ${this.imageCatAlt != null
    ? `cardCategoriesAlternative:"${this.imageCatAlt.id}",`
    : ''
} 
                         
              
              }){
                  client{
                    id
                    name
                    cardContents{
                      id
                      name
                    }
                    cardCategories{
                      id
                      name
                    }
                    cardContentsMobile{
                      id
                      name
                    }
                    cardCategoriesMobile{
                      id
                      name
                    }
                    
                  }                  
                }
              }
        `,
        })
        .then(res => {
          messageError(res, this)

          showToast(this.$t('code.card'), 1, this)
          this.showCard = false
        })
        .catch(() => {
          showToast(this.$t('code.errorcard'), 2, this)
          this.showCard = false
        })
    },
    updateTemplates(event) {
      this.showTemplates = !this.showTemplates
      event.preventDefault()
      const { id } = this.userData.profile.client
      axios
        .post('', {
          variables: { templates: this.templatesId },
          query: `
            mutation($templates: ID){
            updateClient(id:"${id}",input:{
            templates: $templates
              }){
              client{
                id
                name
               
              }
            }
          }
        `,
        })
        .then(res => {
          messageError(res, this)

          showToast(this.$t('success'), 1, this)

          this.showTemplates = !this.showTemplates
        })
        .catch(() => {
          showToast(this.$t('error'), 2, this)

          this.showTemplates = !this.showTemplates
        })
    },
    updateCardProducts(event) {
      this.showProducts = true
      event.preventDefault()
      const { id } = this.userData.profile.client
      axios
        .post('', {
          query: `
            mutation{
            updateClient(id:"${id}",input:{
              
              ${this.cardProducts != null
    ? `cardProducts:"${this.cardProducts.id}",`
    : ''}

              ${this.cardCategoriesProducts != null
    ? `cardCategoriesProducts:"${this.cardCategoriesProducts.id}",`
    : ''}              
              }){
              client{
                id
                name
                cardProducts{
                  id
                  name
                }
                cardCategoriesProducts{
                  id
                  name
                }
               
              }
              
            }
          }
        `,
        })
        .then(res => {
          messageError(res, this)

          showToast(this.$t('code.card'), 1, this)

          this.showProducts = false
        })
        .catch(() => {
          showToast(this.$t('code.errorcard'), 2, this)

          this.showProducts = false
        })
    },
    buildImageUrl(folder) {
      return folder == null || folder.length === 0
        ? noCover
        : folder
    },
    updateLottie() {
      // eslint-disable-next-line no-restricted-globals
      event.preventDefault()

      const { id } = this.userData.profile.client

      const config = {
        timeout: 1000 * 60 * 30,
      }
      const data = new FormData()
      const query = `
      mutation{
        updateClient(id:"${id}",input:{
            videotype: ${this.selected}          
        }){
          client{
            id
            name
            videotype
            video
           
          }
        }
      }
     `
      data.append('query', query)

      if (this.lottieMobile != null) data.append('lottie_mobile', this.lottieMobile)
      if (this.lottieTv != null) data.append('lottie_tv', this.lottieTv)

      axios
        .post('', data, config)
        .then(res => {
          messageError(res, this)

          showToast(this.$t('success'), 1, this)
          if (this.lottieMobile != null) this.archivoMobile = this.lottieMobile
          if (this.lottieTv != null) this.archivoTv = this.lottieTv
        })
        .catch(() => {
          showToast(this.$t('error'), 2, this)
        })
    },
    templateSelection(data) {
      this.templatesName = data.node.name
      this.templatesId = data.node.id
      this.templatesImage = data.node.sampleImage

      this.$refs['template-modal'].hide()
    },
    updateInteractivitiesLogo(event) {
      this.showInteractivitiesLogo = true
      event.preventDefault()
      const { id } = this.userData.profile.client
      const config = {
        timeout: 1000 * 60 * 30,
      }
      const data = new FormData()
      const query = `
      mutation{
        updateClient(id:"${id}",input:{
          videotype: CON                 
        }){
          client{
            id
            name
            videotype
            video
            logo
            logoMono
          }
        }
      }
     `
      data.append('query', query)
      data.append('logo_interactivities', this.imageLogoInteractivities)

      axios
        .post('', data, config)
        .then(res => {
          messageError(res, this)
          this.getData()

          showToast(this.$t('code.logo'), 1, this)
          this.showInteractivitiesLogo = false
        })
        .catch(() => {
          showToast(this.$t('code.errorLogo'), 2, this)
          this.showInteractivitiesLogo = false
        })
    },
  },
}
</script>
<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";

#branding .card-body {
  padding: 1rem;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  min-height: 1px;
}

#branding .card-body h4 {
  font-size: 1.286rem !important;
}

#branding .base-image-input {
  display: block;

  background-size: cover;
  background-position: center center;
}

#branding .base-color-input {
  display: block;
  border-radius: 15px;
  border: solid 1px;
  border-color: white;
  cursor: pointer;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#branding .base-card-input {
  display: block;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}

#branding .div {
  display: inline-block;
}

#branding #inputColor {
  visibility: hidden;
}

#branding #backgroundColor {
  visibility: hidden;
}

#branding .inputColor {
  width: auto;
  color: white;

  background-size: cover;
  background-position: center center;
}

#branding .placeholder {
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}

#branding .placeholder:hover {
  background: #e0e0e0;
}

#branding ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  display: none;
}

</style>
